import api from './index'
import interfaces from "../constant/interfaces"

/**
 * 获取文章类目
 * @param pageNo 页码
 * @param pageSize 大小
 */
export function articleCategoryList(pageNo = 1, pageSize = 30) {
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ARTICLE_CATEGORY_LIST)}`,{
        pageNo,
        pageSize
    })
}

/**
 * 通过类目id获取文章列表
 * @param categoryId 类目id
 * @param pageNo 页码
 * @param pageSize 大小
 */
export function articleList(categoryId,pageNo = 1,pageSize = 10){
    return api.post(`${interfaces.GET_INTERFACE(interfaces.ARTICLE_LIST)}`,{
        categoryId,
        pageNo,
        pageSize
    })
}

/**
 * 获取文章详情
 * @param id
 * @returns {AxiosPromise<any>}
 */
export function articleInfo(id){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.ARTICLE_INFO)}/${id}`)
}

/**
 * 获取所有公告
 * @returns {AxiosPromise<any>}
 */
export function getPost(){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.ARTICLE_POST)}`)
}

export function links(){
    return api.get(`${interfaces.GET_INTERFACE(interfaces.LINKS)}`)
}

export default {
    articleCategoryList,
    articleList,
    articleInfo,
    getPost,
    links
}

